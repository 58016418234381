import React, { Component } from "react";
import { API } from "aws-amplify";
import Helmet from "react-helmet";
import _ from "lodash";
import moment from "moment-timezone";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { newTeams } from "../libs/shared/team-constants";
import { isTimeTBD } from "../libs/shared/grid-helper";
import TEAMS from "../constants/Teams";

const styles = (theme) => ({
  select: {
    width: "100%",
    borderBottom: "none",
    fontSize: "0.875rem",
  },
  header: {
    backgroundColor: "#f5f5f5",
  },
  bottomActions: {
    position: "sticky",
    bottom: 0,
    padding: "2rem 0",
    zIndex: 999,
    backgroundColor: "#fafafa",
  },
  [theme.breakpoints.up("xl")]: {
    bottomActions: {
      padding: "2rem 0",
    },
  },
  [theme.breakpoints.up("md")]: {
    header: {
      padding: "2rem 0 3rem",
      marginBottom: "2rem",
    },
    teamButton: {
      margin: "0.5rem 1rem",
    },
    bottomActions: {
      padding: "1rem 0",
    },
  },
  [theme.breakpoints.down("sm")]: {
    header: {
      padding: "2rem 1rem",
    },
    teamButton: {
      marginRight: "1rem",
      marginBottom: "1rem",
    },
    bottomActions: {
      padding: "1rem",
    },
  },
  teamsList: {
    marginBottom: "1.5rem",
  },
  teamItemWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  teamLogoWrapper: {
    marginBottom: "1.5rem",
    cursor: "pointer",
  },
  teamLogo: {
    height: 75,
    width: 75,
  },
});

const leagueOptions = {
  nhl: 'NHL',
  nba: 'NBA',
  mlb: 'MLB',
  cfl: 'CFL',
  ahl: 'AHL',
  mls: 'MLS',
  whl: 'WHL',
};

class NewListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isSubmitted: false,
      selectedTeam: null,
      selectedLeague: null,
      games: null,
      filteredGames: null,
      selectedGames: [],
      isSpecialSelected: false,
      isNormalSelected: false,
      isLoading: false,
    };

    this.selectAllGames = this.selectAllGames.bind(this);

    this.startListing = this.startListing.bind(this);
  }

  _onSelectLeague = (league) => {
    const leagueTeams = TEAMS.filter(team => team.type === league);
    if (leagueTeams.length === 1) {
      this.setState({
        ...this.state,
        selectedLeague: league
      });
      this.selectTeam(leagueTeams[0].homeTeamSlug);
    } else {
      this.setState({
        ...this.state,
        selectedLeague: league,
        selectedTeam: null,
      });
    }    
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated || !this.props.currUser) {
      this.props.history.push("/login");
    }
    
    const url = window.location.href;
    let isDevelopmentServer = false;
    if (
      url.includes("development") ||
      url.includes("localhost") ||
      url.includes("coupons") ||
      url.includes("staging")
    ) {
      isDevelopmentServer = true;
    }

    const sellData = JSON.parse(localStorage.getItem("sellListingData"));
    const selectedGames =
      sellData &&
      sellData.games &&
      sellData.games.filter(
        (game) =>
          game.date >= moment.tz(game.timezone).subtract(1, "hours").valueOf()
      );

    this.setState({ isLoading: true });
    const games = await this.games();
    this.setState({ isLoading: false });

    if (isDevelopmentServer) {
      var tbdGames = _.chain(games)
        .filter((game) => game.isTbd && !game.isArchived)
        .sortBy("playoffSequence");

      var regularGames = _.chain(games)
        .filter(
          (game) =>
            game.date >=
              moment.tz(game.timezone).subtract(1, "hours").valueOf() &&
            !game.isArchived &&
            !game.isTbd
        )
        .sortBy("date")
        .value();
    } else {
      var tbdGames = _.chain(games)
        .filter((game) => game.isTbd && !game.isArchived && !game.testGame)
        .sortBy("playoffSequence");

      var regularGames = _.chain(games)
        .filter(
          (game) =>
            game.date >=
              moment.tz(game.timezone).subtract(1, "hours").valueOf() &&
            !game.isArchived &&
            !game.isTbd &&
            !game.testGame
        )
        .sortBy("date")
        .value();
    }

    const sortedGames = [...regularGames, ...tbdGames];
    const filteredGames = sellData
      ? sortedGames.filter(
          (game) =>
            game.homeTeamSlug === sellData.selectedTeam
        )
      : sortedGames;
    const selectedTeam = sellData ? sellData.selectedTeam : null;
    const team = selectedTeam ? TEAMS.find(team => team.homeTeamSlug === selectedTeam) : null;
    const selectedLeague = team ? team.type : null;

    this.setState({
      games: sortedGames,
      filteredGames: sellData ? filteredGames : null,
      selectedGames: sellData ? selectedGames : [],
      selectedTeam,
      selectedLeague,
      isSpecialSelected:
        sellData &&
        sellData.games &&
        sellData.games.length > 0 &&
        sellData.games[0].isSpecial,
      isNormalSelected:
        sellData &&
        sellData.games &&
        sellData.games.length > 0 &&
        !sellData.games[0].isSpecial,
    });
  }

  selectAllGames = () => {
    const { filteredGames, isAllGamesSelected } = this.state;
    if (!isAllGamesSelected) {
      const gameIds = _.chain(filteredGames)
        .filter((game) => !game.isSpecial)
        .map((game) => {
          return {
            price: 0,
            ...{
              id: game.id,
              opponent: game.opponent,
              city: game.city,
              date: game.date,
              isPlayoffs: game.isPlayoffs,
              showOpponent: game.showOpponent,
              round: game.round,
              game: game.game,
              gameNo: game.gameNo,
              orderNo: game.orderNo,
              longName: game.longName,
              isTbd: game.isTbd,
              shortName: game.shortName,
              isApproved: false,
              isRejected: false,
              isSpecial: game.isSpecial,
              timezone: game.timezone,
            },
          };
        })
        .value();
      this.setState({
        selectedGames: gameIds,
        isAllGamesSelected: !isAllGamesSelected,
        isNormalSelected: true,
        isSpecialSelected: false,
      });
    } else {
      this.setState({
        selectedGames: [],
        isAllGamesSelected: !isAllGamesSelected,
        isNormalSelected: false,
      });
    }
  };

  handleToggle = (game) => () => {
    const { selectedGames } = this.state;
    const currentIndex = _.findIndex(selectedGames, (o) => o.id === game.id);
    let newSelected = [...selectedGames];

    this.setState({
      isAllGamesSelected: false,
    });
    if (currentIndex === -1) {
      if (game.isSpecial) {
        newSelected = [];

        this.setState({
          isSpecialSelected: true,
        });
      } else {
        this.setState({
          isNormalSelected: true,
        });
      }

      newSelected.push({
        price: 0,
        ...{
          id: game.id,
          opponent: game.opponent,
          city: game.city,
          date: game.date,
          isPlayoffs: game.isPlayoffs,
          showOpponent: game.showOpponent,
          round: game.round,
          game: game.game,
          gameNo: game.gameNo,
          orderNo: game.orderNo,
          isApproved: false,
          isRejected: false,
          longName: game.longName,
          shortName: game.shortName,
          isSpecial: game.isSpecial,
          isTbd: game.isTbd,
          timezone: game.timezone,
        },
      });
    } else {
      if (game.isSpecial) {
        newSelected = [];

        this.setState({
          isSpecialSelected: false,
        });
      } else if (selectedGames.length === 1) {
        this.setState({
          isNormalSelected: false,
        });
      }

      newSelected.splice(currentIndex, 1);
    }

    this.setState({
      selectedGames: newSelected,
    });
  };

  selectTeam(slug) {
    this.setState({
      isSpecialSelected: false,
      isAllGamesSelected: false,
      isNormalSelected: false,
    });

    if (this.state.games) {
      const filteredGames = this.state.games.filter(
        (game) => game.homeTeamSlug === slug
      );

      this.setState({
        selectedTeam: slug,
        filteredGames,
        selectedGames: [],
      });
    }
  }

  games() {
    return API.get("v2", `marketplace/events/all?includePrevious=true`);
  }

  startListing() {
    const { selectedGames, selectedTeam } = this.state;
    const { currUser } = this.props;

    const sellData = {
      games: _.sortBy(selectedGames, "date"),
      selectedTeam,
      email: currUser.email,
      fullName: currUser.name,
      mobileNo: currUser.phone_number,
      slug: selectedTeam,
    };

    const currentSellData = JSON.parse(localStorage.getItem("sellListingData"));

    let newSellData;

    if (currentSellData) {
      newSellData = {
        ...currentSellData,
        ...sellData,
      };
    } else {
      newSellData = sellData;
    }

    localStorage.setItem("sellListingData", JSON.stringify(newSellData));
    this.props.history.push("/sell/set-prices");
  }

  renderGames() {
    const { classes } = this.props;
    const {
      isAllGamesSelected,
      selectedGames,
      selectedLeague,
      filteredGames,
      isSpecialSelected,
      isNormalSelected,
      selectedTeam,
    } = this.state;

    return (
      selectedLeague &&
      selectedTeam &&
      filteredGames && (
        <div>
          <List className={classes.gamesList}>
            {filteredGames.map((game) => {
              const index = _.findIndex(
                selectedGames,
                (item) => item.id === game.id
              );

              const regularGameDate = `${isTimeTBD(game) ? moment
                .tz(game.date, game.timezone)
                .format("ddd MMM DD ")+"TBD" : moment
                .tz(game.date, game.timezone)
                .format("ddd MMM DD h:mm A")}`;
              const gameDate = game.isTbd ? "TBD" : regularGameDate;

              return (
                <ListItem
                  key={game.id}
                  button
                  onClick={this.handleToggle(game)}
                  disabled={
                    (!game.isSpecial && isSpecialSelected) ||
                    (game.isSpecial && isNormalSelected)
                  }
                >
                  <ListItemText primary={game.shortName} secondary={gameDate} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      onChange={this.handleToggle(game)}
                      checked={index >= 0}
                      disabled={
                        (!game.isSpecial && isSpecialSelected) ||
                        (game.isSpecial && isNormalSelected)
                      }
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>

          <Grid container className={classes.bottomActions}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={this.selectAllGames}
                    checked={isAllGamesSelected}
                  />
                }
                label="Select All Games"
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth={true}
                style={{ float: "right" }}
                variant="contained"
                color="primary"
                disabled={selectedGames && selectedGames.length < 1}
                onClick={this.startListing}
              >
                Start Listing
              </Button>
            </Grid>
          </Grid>
        </div>
      )
    );
  }

  renderHeader = () => {
    const { classes } = this.props;
    const { selectedTeam, games, selectedLeague } = this.state;

    return (
      <div className={classes.header}>
        <Grid container justify="center">
          <Grid item xs={12} md={6} xl={4}>
            <Typography
              align="center"
              variant="headline"
              color="primary"
              style={{
                fontWeight: "bold",
                marginTop: "100px",
                marginBottom: "1rem",
              }}
            >
              Select your team
            </Typography>

            <Typography
              align="center"
              variant="subheading"
              style={{
                marginBottom: "2rem",
              }}
            >
              Select the league, the team and the game(s) you wish to add to your listings.
            </Typography>
            <Grid container justify="center" style={{borderBottom: 'solid 2px #D42604', marginBottom: "16px"}}>
              {Object.keys(leagueOptions).map((key, index) => (
                  <Button
                    key={index}
                    className={classes.teamButton}
                    disabled={!games}
                    variant={
                      selectedLeague === leagueOptions[key] ? "contained" : "outlined"
                    }
                    style={{
                      width: "115px",
                      background:
                      selectedLeague === leagueOptions[key]
                          ? "#D42604"
                          : "rgba(0,0,0, 0)",
                      color:
                        selectedLeague === leagueOptions[key] ? "white" : "inherit",
                    }}
                    onClick={() => this._onSelectLeague(leagueOptions[key])}
                    size="medium"
                  >
                    {leagueOptions[key]}
                </Button>
              ))}
            </Grid>
            <Grid container justify="center">
              {TEAMS.filter(team => team.type === selectedLeague).map(team => (
                <Button
                  key={team.homeTeamSlug}
                  className={classes.teamButton}
                  disabled={!games}
                  variant={
                    selectedTeam === team.homeTeamSlug ? "contained" : "outlined"
                  }
                  style={{
                    background:
                      selectedTeam === team.homeTeamSlug
                        ? team.colors.attribute
                        : "rgba(0,0,0, 0)",
                    color:
                      selectedTeam === team.homeTeamSlug ? "white" : "inherit",
                  }}
                  onClick={() => this.selectTeam(team.homeTeamSlug)}
                  size="medium"
                >
                  {team.fullName}
                </Button>
              ))}             
            </Grid>
            {newTeams[selectedTeam] &&
              new Date().valueOf() < 1667260800000 && ( // only display before Nov 1
                <Grid
                  container
                  justify="center"
                  style={{
                    textAlign: "center",
                    marginTop: "12px",
                    color: newTeams[selectedTeam].colorAttribute,
                  }}
                >
                  {newTeams[selectedTeam].name} sellers! Pay no commission for
                  any September and October games sold! Good luck!
                </Grid>
              )}
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const currentURL = window.location.href;
    return (
      <React.Fragment>
        <Helmet>
          <title>New Listing</title>
          <meta name="description" content="New ticket listing by sellers" />
          <meta name="keywords" content={`new listing, games, tickets, buy tickets, fansfirst, no fees, buy NHL/NBA/MLB/CFL tickets`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="New Listing" />
          <meta property="og:description" content="New ticket listing by sellers" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={currentURL} />
          <link rel="canonical" href={currentURL} />
        </Helmet>
        {this.renderHeader()}
        <Grid container>
          <Grid item xs={12} md={12}>
            <Grid container justify="center">
              <Grid item xs={12} md={6} xl={5}>
                {/* {this.renderLogos()} */}
                {this.state.isLoading && (
                  <Typography variant="subheading" align="center">
                    Loading...
                  </Typography>
                )}
                {this.selectTeam && this.renderGames()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(NewListing);
