const TEAMS = [
  {
    name: "Flames",
    fullName: "Calgary Flames",
    homeTeamSlug: "calgary-flames",
    type: "NHL",
    zones: ["Press Level", "Second Level", "Lower Bowl"],
    colors: {
      attribute: "#D42604",
      background: "rgba(212,38,4,0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Jets",
    fullName: "Winnipeg Jets",
    homeTeamSlug: "winnipeg-jets",
    type: "NHL",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    colors: {
      attribute: "#004c97",
      background: "rgba(0, 76, 151, 0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Oilers",
    fullName: "Edmonton Oilers",
    homeTeamSlug: "edmonton-oilers",
    type: "NHL",
    zones: ["Upper Bowl", "Lower Bowl", "Premium Zone"],
    colors: {
      attribute: "#FF4B00",
      background: "rgba(255,75,0,0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Canucks",
    fullName: "Vancouver Canucks",
    homeTeamSlug: "vancouver-canucks",
    type: "NHL",
    zones: ["Upper Bowl", "Lower Bowl", "Club Lower"],
    colors: {
      attribute: "#008852",
      background: "rgba(0,136,82,0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Maple Leafs",
    fullName: "Toronto Maple Leafs",
    homeTeamSlug: "toronto-maple-leafs",
    type: "NHL",
    zones: ["Upper Zone", "Lower Zone", "Suites"],
    colors: {
      attribute: "#001b5a",
      background: "rgba(0,27,90,0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Senators",
    fullName: "Ottawa Senators",
    homeTeamSlug: "ottawa-senators",
    type: "NHL",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    colors: {
      attribute: "#b79257",
      background: "rgba(183,146,87,0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Raptors",
    fullName: "Toronto Raptors",
    homeTeamSlug: "toronto-raptors",
    type: "NBA",
    zones: ["Upper Zone", "Lower Zone", "Premium Zone"],
    colors: {
      attribute: "#7637bf",
      background: "gba(118,55,191,0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Blue Jays",
    fullName: "Toronto Blue Jays",
    homeTeamSlug: "toronto-blue-jays",
    type: "MLB",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    colors: {
      attribute: "#002d5f",
      background: "rgba(0,45,95,0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Stampeders",
    fullName: "Calgary Stampeders",
    homeTeamSlug: "calgary-stampeders",
    type: "CFL",
    zones: ["Lower Zone", "Other", "-"],
    colors: {
      attribute: "#d60000",
      background: "rgba(214,0,0,0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Elks",
    fullName: "Edmonton Elks",
    homeTeamSlug: "edmonton-elks",
    type: "CFL",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    colors: {
      attribute: "#2B5134",
      background: "rgba(43,81,52,0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Blue Bombers",
    fullName: "Winnipeg Blue Bombers",
    homeTeamSlug: "winnipeg-blue-bombers",
    type: "CFL",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    colors: {
      attribute: "#1D3D7A",
      background: "rgba(29,61,122,0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Lions",
    fullName: "BC Lions",
    homeTeamSlug: "bc-lions",
    type: "CFL",
    zones: ["Upper Zone", "Club Seats", "Lower Zone"],
    colors: {
      attribute: "#F15623",
      background: "rgba(241,86,35,0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Argonauts",
    fullName: "Toronto Argonauts",
    homeTeamSlug: "toronto-argonauts",
    type: "CFL",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    colors: {
      attribute: "#5F8FB1",
      background: "rgba(95,143,177,0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Redblacks",
    fullName: "Ottawa Redblacks",
    homeTeamSlug: "ottawa-redblacks",
    type: "CFL",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    colors: {
      attribute: "#AC1F2D",
      background: "rgba(172,31,45,0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Roughriders",
    fullName: "Saskatchewan Roughriders",
    homeTeamSlug: "saskatchewan-roughriders",
    type: "CFL",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    colors: {
      attribute: "#00623f",
      background: "rgba(0, 98, 63,0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Tiger-Cats",
    fullName: "Hamilton Tigercats",
    homeTeamSlug: "hamilton-tigercats",
    type: "CFL",
    zones: ["Upper Zone", "Premium Zone", "Lower Zone"],
    colors: {
      attribute: "#FFB819",
      background: "rgba(255,184,25,0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Grey Cup",
    fullName: "Grey Cup",
    homeTeamSlug: "grey-cup",
    type: "CFL",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    colors: {
      attribute: "#1D3D7A",
      background: "rgba(29,61,122,0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Wranglers",
    fullName: "Calgary Wranglers",
    homeTeamSlug: "calgary-wranglers",
    type: "AHL",
    zones: ["Press Level", "Second Level", "Lower Bowl"],
    colors: {
      attribute: "#C2273D",
      background: "rgba(206, 14, 45,0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Moose",
    fullName: "Manitoba Moose",
    homeTeamSlug: "manitoba-moose",
    type: "AHL",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    colors: {
      attribute: "#004c97",
      background: "rgba(0, 76, 151, 0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Whitecaps FC",
    fullName: "Vancouver Whitecaps FC",
    homeTeamSlug: "vancouver-whitecaps-fc",
    type: "MLS",
    zones: ["General Admission", "Middle Zone", "Lower Zone"],
    colors: {
      attribute: "#9DC2EA",
      background: "rgba(157, 194, 234, 0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Toronto FC",
    fullName: "Toronto FC",
    homeTeamSlug: "toronto-fc",
    type: "MLS",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    colors: {
      attribute: "#B81137",
      background: "rgba(184, 17, 55, 0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Abbotsford Canucks",
    fullName: "Abbotsford Canucks",
    homeTeamSlug: "abbotsford-canucks",
    type: "AHL",
    zones: ["-", "-", "Lower Zone"],
    colors: {
      attribute: "#047835",
      background: "rgba(4, 120, 53,0.3)",
    },
    category: 'Teams',
  },
  {
    name: "Belleville Senators",
    fullName: "Belleville Senators",
    homeTeamSlug: "belleville-senators",
    type: "AHL",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    colors: {
      attribute: "#E3173E",
      background: "rgba(227, 23, 62, 0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Toronto Marlies",
    fullName: "Toronto Marlies",
    homeTeamSlug: "toronto-marlies",
    type: "AHL",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    colors: {
      attribute: "#003E7E",
      background: "rgba(0, 62, 126, 0.1)",
    },
    category: 'Teams',
  },
  {
    name: "Hitmen",
    fullName: "Calgary Hitmen",
    homeTeamSlug: "calgary-hitmen",
    type: "WHL",
    zones: ["Press Level", "Second Level", "Lower Bowl"],
    colors: {
      attribute: "#D42604",
      background: "rgba(212,38,4,0.3)",
    },
    category: 'Teams',
  },
];

export default TEAMS;
